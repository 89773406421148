const getUrlFromEnv = (env: string) => {
  switch (env) {
    case "development":
      return "http://localhost:8080";
    case "production":
      return "https://api.bank.slosite.com";
    default:
      throw new Error(`Unhandled environment: ${env}`);
  }
};

export const getApiBaseUrl = () => {
  return getUrlFromEnv(process.env.NODE_ENV);
};
