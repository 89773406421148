import { Container, CssBaseline } from "@material-ui/core";
import React from "react";
import { UserTable } from "./components/UserTable";

function App() {
  return (
    <Container fixed>
      <h1>Users</h1>
      <UserTable />
    </Container>
  );
}

function Root() {
  return (
    <React.Fragment>
      <CssBaseline />
      <App />
    </React.Fragment>
  );
}

export default Root;
