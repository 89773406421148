import { getApiBaseUrl } from "../config";
import axios from "axios";

export type User = {
  id: string;
  firstName: string;
  lastName: string;
};

export type TestUser = {
  id: string;
  name: string;
};

class ApiClient {
  constructor(private baseUrl: string) {}

  async getUsers(): Promise<User[]> {
    const response = await axios.get(`${this.baseUrl}/api/user`);
    return response.data;
  }

  async getTestUsers(): Promise<User[]> {
    const response = await axios.get(`${this.baseUrl}/api/test/user`);
    return response.data.map((user: TestUser) => ({
      id: user.id,
      firstName: user.name,
      lastName: "",
    }));
  }
}

export const apiClient = new ApiClient(getApiBaseUrl());
