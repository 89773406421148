import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { FunctionComponent, useEffect, useState } from "react";
import { apiClient, User } from "../lib/ApiClient";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const UserTableFn: FunctionComponent<{ users: User[] }> = ({ users }) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>User ID</TableCell>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow key={user.id}>
              <TableCell>{user.id}</TableCell>
              <TableCell>{user.firstName}</TableCell>
              <TableCell>{user.lastName}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const UserTable = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const users = await apiClient.getTestUsers();
        setUsers(users);
      } catch (err) {
        console.error(err);
        setError("Failed to get users ...");
      }
    })();
  }, []);

  return (
    <>
      <UserTableFn users={users} />
      {error && <p style={{ color: "red" }}>{error}</p>}
    </>
  );
};
